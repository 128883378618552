<template lang='pug'>
  extends ../templates/PageBase.pug
  block content
    .card.card-transparent
      .card-body.no-padding
        .row
          .col-xl-12
            .card.card-transparent.flex-row

              ul#tab-3.nav.nav-tabs.nav-tabs-simple.nav-tabs-left.bg-white
                li.nav-item(v-for='(m, i) in mainModules')
                  a(href='#' data-toggle='tab' v-html='m.name' :class='{active: i === 0}')
              .tab-content.bg-white

                #tab3hellowWorld.tab-pane.active
                  .row.column-seperation
                    .col-lg-12
                      ul.nav.nav-tabs.nav-tabs-simple(role='tablist', data-init-reponsive-tabs='dropdownfx')
                        li.nav-item(v-for='(m, i) in subModules')
                          router-link(
                            data-toggle='tab'
                            data-target='#tab3hellowWorld'
                            v-html='m.label'
                            :class='{active: m.course === $route.params.course}'
                            :to='{name: "python", params: {course: m.course}}'
                          )
                      .tab-content
                        #tab1.tab-pane.sm-no-padding.active.slide-left
                          .row.row-same-height
                            .col-md-4.b-r.b-dashed.b-grey.sm-b-b
                              .sm-padding-5.sm-m-t-15.m-t-0.p-t-0
                                h2 {{ headline }}
                                p(v-html='description')
                                p(v-if='exercise')
                                  strong Exercise 💪:&nbsp;
                                  span(v-html='exercise')
                                p(v-if='tip')
                                  strong Tip 💡:&nbsp;
                                  span(v-html='tip')

                                template(v-if='nextRoute && gotoNext')
                                  p &nbsp;
                                  p If you are done, we can proceed to the next Section, where we learn about
                                  router-link.btn.btn-block.btn-primary(
                                    :to='{name: "python", params: {course: nextRoute.route}}'
                                    href='javscript:;'
                                    type='button'
                                  )
                                    span.bold {{ nextRoute.name }}

                                p.small.hint-text.m-t-50(v-html='hint')
                            .col-md-8
                              python-editor(
                                mime-type='text/plain'
                                mode='python'
                                file-ext='py'
                                height='400px'
                                :validators='stepValidators'
                                ref='helloWorld')
                              p.small.hint-text.m-t-10
                                | Click on <i class='fa fa-play text-primary'></i> or press Ctrl+Enter (Win) or CMD+Enter (Mac) to run the Python-Code

    .row
      .col-lg-12
        .card.card-transparent
          .card-header
            .card-title
              | Portlet Tools
          .card-body
            .row
              .col-lg-4
                .card.card-default(data-pages='card')
                  .card-header
                    .card-title
                      | About
                  .card-body
                    h4
                      span.semi-bold Why does this site exists?
                    p
                      | In 2004 i came in touch with Python and fell in love with it.
                      | Since then i solved as much with python as possible.
                    p
                      | I felt humble seeing python growing up to one the most used languages on Github and top 5 Languages on TIOBE.
                      |
                      | This is why this Tutorial exists.

                    h4
                      span.semi-bold How does it work?
                    p
                      | Ironically, this Website comes without any Backend Code.
                      | The python code we're running, were transpiled to JS and runs on the Browser without a server to process it.
                      | This allows us to run python code within milliseconds and doesn't need any servers to scale.
                    p
                      | Scaling python executable serverside can be very challenging, expensive and there are security issues with executing python code.
                      | Four our purpose, to get in touch with python - transpiling to JS is sufficient.
              .col-lg-4
                .card.card-default(data-pages='card')
                  .card-header
                    .card-title
                      | Subscribe for Updates
                  .card-body
                    h3 Hey 👋, i hope you find this Tutorial
                      span.semi-bold &nbsp; helpfull.
                    p
                      | This is just the beginning of our Python-Journey, there is a lot to learn and to discover.
                      | Support us by subscribing to our Newsletters, so we can tell you about updates and new Tutorials.
                    include ../templates/mailChimpSubscribe.pug

              .col-lg-4
                .card.card-default(data-pages='card')
                  .card-header
                    .card-title
                      | Comment Section
                  .card-body
                    div#disqus_thread

    code.hidden(ref='exp1')
      | print("Hello World")

</template>

<script>
  import axios from 'axios'
  import PythonEditor from '../components/PythonEditor'

  const mainModules = [
    {
      name: 'Basics',
      id: 'basics',
      modules: [{name: 'Print'}, {name: 'Variables'}]
    },
    {
      name: 'Flow<br>Control',
      id: 'flow-control',
      modules: []
    }
  ]

  const subModules = [
    {label: 'Print', course: 'basics'},
    {label: 'Variables', course: 'variables'},
    {label: 'Operators', course: 'operators'},
  ]

  export default {
    name: 'Python',
    components: {PythonEditor},
    data() {
      return {
        headline: '',
        description: '',
        hint: '',
        tip: '',
        exercise: '',
        steps: [],
        gotoNext: false,
        nextRoute: {},
        stepValidators: [],
        mainModules: mainModules,
        subModules: subModules
      }
    },
    watch: {
      '$route'(to, from) {
        this.contruct()
      }
    },
    methods: {
      setupDisqus() {
        const self = this

        if (document.querySelectorAll('script[data-timestamp]').length === 0) {
          (function () { // DON'T EDIT BELOW THIS LINE
            const d = document, s = d.createElement('script')
            s.src = 'https://annoyingdev-1.disqus.com/embed.js'
            s.setAttribute('data-timestamp', + new Date());
            (d.head || d.body).appendChild(s)
          })()
        } else {
          window.DISQUS.reset({
            reload: true,
            config: function () {
              this.page.identifier = this.$route.params.course
              this.page.url = `https://annoyingdev.com${self.$route.path}`
            }
          })
        }
      },
      contruct() {
        const self = this
        self.gotoNext = false

        if (process.env.NODE_ENV !== 'development') {
          this.setupDisqus()
        }

        axios.get(`/learningContent/${this.$route.params.course}.json`).then(function (resp) {
          const steps = resp.data.steps
          self.steps = steps
          self.nextRoute = {...resp.data.next}

          self.stepValidators = steps.map((e) => e.validator)

          self.headline = steps[0].headline
          self.description = steps[0].description
          self.hint = steps[0].hint
          self.tip = steps[0].tip
          self.exercise = steps[0].exercise

          if (steps[0].code) {
            self.$refs.helloWorld.setContent(steps[0].code)
          }
        }).catch(function (err) {
          window.console.log('# ahhh EXECTON', err)
        })
      }
    },
    mounted() {
      const self = this

      this.contruct()

      this.$on('assertCompleted', function (data) {
        if (data.type === 'success') {
          const currentPath = self.steps[data.step]
          if (currentPath) {
            self.headline = currentPath.headline
            self.description = currentPath.description

            if (currentPath.hint) {
              self.hint = currentPath.hint
            }
            if (currentPath.code) {
              self.$refs.helloWorld.setContent(currentPath.code)
            }

            self.tip = currentPath.tip ? currentPath.tip : ''
            self.exercise = currentPath.exercise ? currentPath.exercise : ''
          }

          // End reached
          if (data.step === self.steps.length-1) {
            self.gotoNext = true
          }

        }
      })
    }
  }
</script>

<style lang="scss">
  #mergeRow-gdpr {
    margin-top: 20px;
  }

  #mergeRow-gdpr fieldset label {
    font-weight: normal;
  }

  #mc-embedded-subscribe-form .mc_fieldset {
    border: none;
    min-height: 0px;
    padding-bottom: 0px;
  }

  .p-t-0 {
    padding-top: 0px !important;
  }

  a[type="button"] {
    -webkit-appearance: initial;
  }

  .card {
    .tab-content {
      width: 100%;
      padding-left: 15px;
      padding-right: 0;
    }
  }

  .note-editor {
    .row {
      margin: 0
    }
  }
</style>
