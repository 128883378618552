<template lang='pug'>
  .summernote-wrapper
    .note-editor
      .note-toolbar.btn-toolbar(ref='toolbar')
        .btn-group(v-for='control in controls')
          button.btn.btn-default.btn-sm.btn-small(
          :class='{"dropdown-toggle": control.items, "button-highlight": control.highlight}'
          @click='callAction(control.action)'
          :data-toggle='control.items ? "dropdown" : ""')

            i.fa(:class='control.icon')
            span.caret(v-if='control.items')
          ul.dropdown-menu(v-if='control.items')
            li(v-for='e in control.items')
              a(:class='{"checked": e.spaces === tabSettings.spaces}' @click='callAction(e.action)')
                i.fa.fa-check.icon-ok
                | {{ e.label }}

      .row(ref='outputContainer')
        .col-md-6.no-margin.no-padding
          .editor.input(ref='editor')
          div(ref='debugOut')
        .col-md-6.no-margin.no-padding
          .editor.output(ref='output')
</template>

<script>
  import CodeEditor from './CodeEditor'
  import ace from 'ace-builds/src-noconflict/ace'
  import theme from 'ace-builds/src-noconflict/theme-dawn'

  const CDN = 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.1/src-min-noconflict'
  ace.config.set('basePath', CDN)
  ace.config.set('modePath', CDN)
  ace.config.set('themePath', CDN)
  ace.config.set('workerPath', CDN)

  const controls = [
    {
      label: 'Run Python',
      icon: 'fa-play icon-play',
      highlight: true,
      action: {func: 'runPython', args: null}
    }
  ]
  export default {
    extends: CodeEditor,
    name: 'PythonEditor',
    mounted() {
      const self = this
      this.pyScriptTag = document.createElement('script')
      this.pyScriptTag.setAttribute('type', 'text/python3')
      window.document.head.appendChild(this.pyScriptTag)

      this.overrideLog()

      this.$refs.output.style.height = this.height
      this.editorOutput = ace.edit(this.$refs.output, {readOnly: true})
      this.editorOutput.setTheme(theme)
      this.editorOutput.session.setMode(`ace/mode/sh`)

      this.editor.commands.addCommand({
        name: 'Run Python',
        bindKey: {win: 'Ctrl-Enter', mac: 'Command-Enter'},
        exec: function () {
          self.runPython()
        }
      })

      // this.loadScripts([
      //   '/js/brython.js',
      //   '/js/brython_stdlib.js'
      // ])
    },
    props: {
      validators: {default: () => []},
      controls: {default: () => controls}
    },
    data() {
      return {
        validatorIndex: 0,
        output: '',
        pyScriptTag: null
      }
    },
    computed: {
      expOutput() {
        return this.validatorIndex < this.validators.length ? this.validators[this.validatorIndex] : '.*'
      }
    },
    watch: {
      validators() {
        this.validatorIndex = 0
      },
      output(newVal) {
        const stripped = newVal.trim()
        let msg = `<a class="emoji">😱</a> there is a <a class="emoji">🐞</a> in your code! Fix it and try again.`
        let type = 'danger'
        const re = new RegExp(this.expOutput)

        if (stripped.search(re) > -1) {
          if (this.validatorIndex < this.validators.length -1) {
            this.validatorIndex += 1
          }

          msg = `Well done <a class="emoji">🎉</a>! Your code is correct.`
          type = 'success'
        }

        this.showMessage(msg, type)

        this.$parent.$emit('assertCompleted', {type: type, step: this.validatorIndex})
      }
    },
    methods: {
      showMessage(msg, type) {
        window.$('.pgn.push-on-sidebar-open.pgn-bar').remove()
        window.$(this.$refs.outputContainer).pgNotification({
            style: 'bar',
            message: msg,
            position: 'bottom',
            timeout: 4000,
            showClose: true,
            type: type
          }).show()
      },
      overrideLog() {
        const self = this
        self.output = ''

        const log = window.console.log
        window.console.log = function () {
          log.apply(this, Array.prototype.slice.call(arguments))

          const line = arguments[0]

          if (line.search(/(indexedDB)|(^%.*$)|(^#.*$)|(upgrade needed)/gi) === -1) {
            self.output += line

            self.editorOutput.setValue(self.output)
            self.editorOutput.gotoLine(1, 0)
          }
        }
      },
      loadScripts(scripts) {
        scripts.forEach(function (s) {
          const scr = document.createElement('script')
          scr.setAttribute('src', s)
          document.head.appendChild(scr)
        })
      },
      runPython() {
        const self = this
        self.output = ''
        this.pyScriptTag.innerHTML = this.editor.getValue()
        try {
          window.brython(1)
        } catch (err) {
        }
      }
    }
  }
</script>

<style lang="scss">
  .code-output {
    font-family: Menlo, Monaco, 'Andale Mono', 'Lucida Console', 'Courier New', monospace;
  }

  .note-editor {
    .emoji {
      font-size: 20px;
    }

    .row {
      .pgn-wrapper[data-position='bottom'] {
        position: relative;
        width: 100%;
      }
    }
  }
</style>
